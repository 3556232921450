var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        persistent: "",
        width: _vm.isExistingFeature ? "1800" : "850",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "fill" },
        [
          _c(
            "v-toolbar",
            { staticClass: "fill", attrs: { flat: "" } },
            [
              _c("v-breadcrumbs", {
                attrs: { items: _vm.breadcrumbs },
                scopedSlots: _vm._u([
                  {
                    key: "item",
                    fn: function ({ item }) {
                      return [
                        _c(
                          "v-breadcrumbs-item",
                          {
                            style: !item.disabled ? "cursor:pointer" : "",
                            attrs: { disabled: item.disabled },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("update:value", false)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              { class: !item.disabled ? "primary--text" : "" },
                              [_vm._v(_vm._s(item.text))]
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("v-spacer"),
              _vm.isExistingFeature
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "offset-x": "",
                        left: "",
                        bottom: "",
                        "nudge-right": 5,
                        "nudge-top": 5,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on: menu, attrs }) {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { top: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function ({ on: tooltip }) {
                                            return [
                                              _c(
                                                "v-btn",
                                                _vm._g(
                                                  _vm._b(
                                                    {
                                                      staticClass:
                                                        "white--text",
                                                      attrs: {
                                                        disabled: _vm.readonly,
                                                        icon: "",
                                                        color: "delete",
                                                      },
                                                    },
                                                    "v-btn",
                                                    attrs,
                                                    false
                                                  ),
                                                  { ...tooltip, ...menu }
                                                ),
                                                [
                                                  _c("v-icon", [
                                                    _vm._v("mdi-trash-can"),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [_c("span", [_vm._v("Delete")])]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3584615226
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-6" },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "py-4",
                              attrs: { justify: "center" },
                            },
                            [_c("span", [_vm._v("Delete Feature?")])]
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                { class: _vm.body, attrs: { text: "" } },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary white--text ml-2",
                                  class: _vm.body,
                                  on: { click: _vm.deleteAdFeature },
                                },
                                [_vm._v(" Confirm ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-btn",
                {
                  attrs: { icon: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
          _c(
            "v-card-title",
            { staticClass: "py-0", class: _vm.title },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isExistingFeature ? "Edit Feature" : "Create Feature"
                  ) +
                  " "
              ),
              _vm.isExistingFeature
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { top: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "ml-3",
                                      class: _vm.body,
                                      attrs: { icon: "" },
                                      on: {
                                        click: function ($event) {
                                          _vm.showInfo = !_vm.showInfo
                                        },
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.showInfo
                                            ? "mdi-eye-off"
                                            : "mdi-eye"
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2271292014
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.showInfo ? "Collapse Info" : "Expand Info")
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _c("v-spacer"),
              !_vm.showInfo
                ? _c(
                    "small",
                    [
                      _c("strong", [_vm._v("Page:")]),
                      _vm._v(" " + _vm._s(_vm.feature.page.name) + " "),
                      _c("v-icon", { attrs: { color: "#000" } }, [
                        _vm._v("mdi-circle-small"),
                      ]),
                      _c("strong", [_vm._v("Position:")]),
                      _vm._v(" " + _vm._s(_vm.feature.position.name) + " "),
                      _c("v-icon", { attrs: { color: "#000" } }, [
                        _vm._v("mdi-circle-small"),
                      ]),
                      _c("strong", [_vm._v("Count:")]),
                      _vm._v(" " + _vm._s(_vm.feature.count) + " "),
                      _c("v-icon", { attrs: { color: "#000" } }, [
                        _vm._v("mdi-circle-small"),
                      ]),
                      _c("strong", [_vm._v("Ad Retail:")]),
                      _vm._v(
                        " $" +
                          _vm._s(
                            _vm.formatCurrencyDisplay(_vm.feature.ad_retail)
                          ) +
                          " "
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm.showInfo
            ? _c(
                "v-form",
                { ref: "form", staticClass: "px-6 fill", attrs: { flat: "" } },
                [
                  _c(
                    "v-row",
                    { staticClass: "py-4", attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.featPage } },
                        [
                          _c("v-autocomplete", {
                            class: _vm.body,
                            attrs: {
                              readonly: _vm.readonly,
                              rules: [(v) => !!v.id || "Page Required"],
                              "auto-select-first": "",
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              placeholder: "Select Page",
                              label: "Page",
                              items: _vm.pages,
                              "item-text": "name",
                              "item-value": "id",
                              "return-object": "",
                              autocomplete: "off",
                              "hide-details": "auto",
                              "menu-props": {
                                bottom: true,
                                offsetY: true,
                                maxHeight: 220,
                                rounded: "",
                              },
                            },
                            on: { change: _vm.getPagePositions },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(data.item.name)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              572245801
                            ),
                            model: {
                              value: _vm.localPage,
                              callback: function ($$v) {
                                _vm.localPage = $$v
                              },
                              expression: "localPage",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.featPos } },
                        [
                          _c("v-autocomplete", {
                            class: _vm.body,
                            attrs: {
                              readonly: _vm.readonly,
                              rules: [
                                (v) => !!v.id || "Feature Position Required",
                              ],
                              "auto-select-first": "",
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              placeholder: "Select Feature Postition",
                              label: "Feature Position",
                              items: _vm.pagePositions,
                              "item-text": "name",
                              "item-value": "id",
                              "return-object": "",
                              autocomplete: "off",
                              "hide-details": "auto",
                              "menu-props": {
                                bottom: true,
                                offsetY: true,
                                maxHeight: 220,
                                rounded: "",
                              },
                            },
                            on: {
                              "update:items": function ($event) {
                                _vm.pagePositions = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (data) {
                                    return [
                                      [
                                        _c(
                                          "v-list-item-content",
                                          [
                                            _c("v-list-item-title", [
                                              _vm._v(_vm._s(data.item.name)),
                                            ]),
                                          ],
                                          1
                                        ),
                                      ],
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              572245801
                            ),
                            model: {
                              value: _vm.feature.position,
                              callback: function ($$v) {
                                _vm.$set(_vm.feature, "position", $$v)
                              },
                              expression: "feature.position",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.featText } },
                        [
                          _c("v-text-field", {
                            class: _vm.body,
                            attrs: {
                              readonly: _vm.readonly,
                              dense: "",
                              "background-color": "#fff",
                              outlined: "",
                              label: "Feature Text",
                              placeholder: "Add Feature Text",
                              autocomplete: "off",
                              rules: !_vm.readonly ? _vm.featureTextRules : [],
                              clearable: "",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.feature.feature_text,
                              callback: function ($$v) {
                                _vm.$set(_vm.feature, "feature_text", $$v)
                              },
                              expression: "feature.feature_text",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    {
                      staticClass: "pb-5",
                      attrs: { dense: "", align: "center" },
                    },
                    [
                      _vm.isExistingFeature
                        ? _c(
                            "v-col",
                            { attrs: { cols: _vm.isExistingFeature ? 2 : 2 } },
                            [
                              _c("v-text-field", {
                                class: _vm.body,
                                attrs: {
                                  readonly: _vm.readonly,
                                  rules: !_vm.readonly
                                    ? [
                                        (v) => !!v || "Count Required",
                                        _vm.validate_count,
                                      ]
                                    : [],
                                  outlined: "",
                                  dense: "",
                                  type: "number",
                                  "background-color": "input",
                                  label: "Count",
                                  "prepend-inner-icon": "mdi-pound",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.feature.count,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.feature, "count", $$v)
                                  },
                                  expression: "feature.count",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.isExistingFeature
                        ? _c("v-icon", { attrs: { dense: "" } }, [
                            _vm._v("mdi-at"),
                          ])
                        : _vm._e(),
                      _vm.isExistingFeature
                        ? _c(
                            "v-col",
                            { attrs: { cols: _vm.isExistingFeature ? 2 : 2 } },
                            [
                              _c("v-text-field", {
                                class: _vm.body,
                                attrs: {
                                  readonly: _vm.readonly,
                                  rules: !_vm.readonly
                                    ? [
                                        (v) => !!v || "Ad Retail Required",
                                        _vm.validate_ad_retail,
                                      ]
                                    : [],
                                  outlined: "",
                                  dense: "",
                                  type: "number",
                                  min: "0.01",
                                  "background-color": "input",
                                  label: "Ad Retail",
                                  "prepend-inner-icon": "mdi-currency-usd",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.feature.ad_retail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.feature, "ad_retail", $$v)
                                  },
                                  expression: "feature.ad_retail",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.$auth.tenant !== "pricechopper" &&
                      _vm.$auth.tenant !== "alliance-retail-group"
                        ? _c(
                            "v-col",
                            { attrs: { cols: _vm.isExistingFeature ? 2 : 3 } },
                            [
                              _c("v-text-field", {
                                class: _vm.body,
                                attrs: {
                                  readonly: _vm.readonly,
                                  outlined: "",
                                  dense: "",
                                  "background-color": "input",
                                  label: "Sign Detail",
                                  rules: [_vm.validateText],
                                  clearable: "",
                                  "hide-details": "auto",
                                },
                                model: {
                                  value: _vm.feature.sign_detail,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.feature, "sign_detail", $$v)
                                  },
                                  expression: "feature.sign_detail",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.featNotes } },
                        [
                          _c("v-text-field", {
                            class: _vm.body,
                            attrs: {
                              readonly: _vm.readonly,
                              outlined: "",
                              dense: "",
                              "background-color": "input",
                              label: "Feature Notes (Optional)",
                              rules: _vm.featureNotesRules,
                              clearable: "",
                              "hide-details": "auto",
                            },
                            model: {
                              value: _vm.feature.notes,
                              callback: function ($$v) {
                                _vm.$set(_vm.feature, "notes", $$v)
                              },
                              expression: "feature.notes",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { attrs: { align: "right" } },
                        [
                          _vm.isExistingFeature
                            ? _c(
                                "v-btn",
                                {
                                  attrs: { color: "primary" },
                                  on: { click: _vm.updateAdFeature },
                                },
                                [_vm._v(" Save ")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isExistingFeature ? _c("v-divider") : _vm._e(),
          _vm.isExistingFeature
            ? _c(
                "v-tabs",
                {
                  attrs: {
                    "background-color": "white",
                    "hide-slider": "",
                    height: "35",
                  },
                  model: {
                    value: _vm.tab,
                    callback: function ($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab",
                  },
                },
                [
                  _vm._l(_vm.tabs, function (component, index) {
                    return _c(
                      "v-tab",
                      {
                        key: index,
                        staticClass: "text-capitalize",
                        attrs: { ripple: false, disabled: !component.show },
                      },
                      [
                        component.show
                          ? _c(
                              "span",
                              [
                                _c("v-icon", [_vm._v(_vm._s(component.icon))]),
                                _vm._v(" " + _vm._s(component.name) + " "),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  _vm._l(_vm.tabs, function (component) {
                    return _c(
                      "v-tab-item",
                      { key: component.name },
                      [
                        _c(component.content, {
                          tag: "component",
                          attrs: {
                            tab: _vm.tab,
                            showInfo: _vm.showInfo,
                            selectedAd: _vm.selectedAd,
                            feature: _vm.feature,
                            readonly: _vm.readonly,
                            adParty: _vm.adParty,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.isExistingFeature ? _c("v-divider") : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isExistingFeature ? "Close" : "Cancel") +
                      " "
                  ),
                ]
              ),
              !_vm.isExistingFeature
                ? _c(
                    "v-btn",
                    {
                      staticClass: "green white--text",
                      class: _vm.body,
                      attrs: { disabled: _vm.readonly },
                      on: {
                        click: function ($event) {
                          _vm.isExistingFeature
                            ? _vm.updateAdFeature()
                            : _vm.createAdFeature()
                        },
                      },
                    },
                    [_vm._v(" Create ")]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { value: _vm.modal, persistent: "", width: "500" },
          on: {
            "update:value": function ($event) {
              _vm.modal = $event
            },
          },
        },
        [
          _c(
            "v-card",
            [
              _c(
                "v-card-title",
                { staticClass: "font-weight-light" },
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "red--text",
                      attrs: { large: "", left: "" },
                    },
                    [_vm._v("mdi-alert-octagon-outline")]
                  ),
                  _vm._v(" Are you sure you want to close feature? "),
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "pa-6" },
                [
                  _c("v-row", { attrs: { justify: "center" } }, [
                    _vm._v(" You have unsaved changes that will be lost. "),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      class: _vm.body,
                      attrs: { text: "" },
                      on: {
                        click: function ($event) {
                          _vm.modal = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary white--text ml-2",
                      class: _vm.body,
                      on: {
                        click: function ($event) {
                          return _vm.$emit("update:value", false)
                        },
                      },
                    },
                    [_vm._v(" Confirm ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }